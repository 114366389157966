/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import {Button, Form, ListGroup, Modal} from "react-bootstrap";
import QuickTable from "../../../components/QuickTable";
import AlertModal from "../../../components/AlertModals/AlertModal";
import { useState } from "react";
import KeyValueRow from "../../../components/KeyValueRow";
import { DirectDepositDocuments, OnboardingDocumentsReadOnly, downloadBase64, taxDocumentDictionary } from "../../../tools";
import CustomButton from "../../../components/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown} from "@fortawesome/free-regular-svg-icons";
import { faDownload, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import EDDocumentPreview from "./EDDocumentPreview";
import EdDocumentItem from "./EdDocumentItem.js";
import { AppGlobals } from "../../../App.js";
import CustomAlert from "../../../components/CustomAlert.js";
import { downloadSsnOrDl, sendBackDlOrSsn, sendBackEdDocument, sendDocumentRequestInvitation } from "../../../services/HumanResourcesService.js";
import AttachmentPreview from "./AttachmentPreview.js";
import CustomDocumentItem from "./CustomDocumentItem.js";

export default function DocumentRequestDetails({selectedEmployee, documentRequest, handleSetEDDocumentIncomplete}) {
    const [docToSendBack, setDocToSendBack] = useState(undefined);
    const [showSendBackModal, setShowSendBackModal] = useState(false); 
    const [isSendingBack, setIsSendingBack] = useState(false);
    const [revisionMessage, setRevisionMessage] = useState('');
    const [docToPreview, setDocToPreview] = useState(undefined);
    const [showPreview, setShowPreview] = useState(false);
    const [showSendInvitation, setShowSendInvitation] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(undefined);
    const [showAttachment, setShowAttachment] = useState(false);
    const [isSendingInvitation, setIsSendingInvitation] = useState(false);
    const [dlIsPreviewing, setDlIsPreviewing] = useState(false);
    const [ssnIsPreviewing, setSsnIsPreviewing] = useState(false);
    const [dlIsDownloading, setDlIsDownloading] = useState(false);
    const [ssnIsDownloading, setSsnIsDownloading] = useState(false);

    async function handleSendBackDocument(){
        setIsSendingBack(true);
        let response;
        if(docToSendBack === 'ssn' || docToSendBack === 'dl'){
            response = await sendBackDlOrSsn(documentRequest.uid, docToSendBack, revisionMessage);
        }else{
            if(typeof docToSendBack === 'number'){
                response = await sendBackEdDocument(documentRequest.uid, 'custom', revisionMessage, docToSendBack);
            }else{
                response = await sendBackEdDocument(documentRequest.uid, docToSendBack, revisionMessage);
            }
        }
        if(response.status === 200){
            handleSetEDDocumentIncomplete(documentRequest.uid, docToSendBack);
            setRevisionMessage('');
            setShowSendBackModal(false);
        }
        AppGlobals.alert(<CustomAlert type={response.status === 200 ? 'success' : 'danger'} title={response.message}/>);
        setIsSendingBack(false);
    }

    async function handleDownloadSsnOrDl(documentType, uid){
        documentType === 'ssn' ? setSsnIsDownloading(true) : setDlIsDownloading(true);
        const type = documentType === 'ssn' ? 'Social Security Card' : 'Driver\'s License';
        const response = await downloadSsnOrDl(uid)
        if(response.status === 200){
            downloadBase64(response.base64, `${selectedEmployee.firstName} ${selectedEmployee.lastName} ${type}`);
        }else{
            AppGlobals.alert(<CustomAlert type={response.status === 200 ? 'success' : 'danger'} title={response.message}/>);
        }
        documentType === 'ssn' ? setSsnIsDownloading(false) : setDlIsDownloading(false);
    }

   async function handlePreviewDlSsn(documentType){
        const documentIdentifier =  documentType === 'ssn' ? documentRequest.ssnDataIdentifier : documentRequest.dlDataIdentifier;
        documentType === 'ssn' ? setSsnIsPreviewing(true) : setDlIsPreviewing(true);
        const response = await downloadSsnOrDl(documentIdentifier);
        if(response.status === 200){
            const title = documentType === 'dl' ? `${selectedEmployee.firstName} ${selectedEmployee.lastName} Driver's License` : `${selectedEmployee.firstName} ${selectedEmployee.lastName} Social Security Card`;
            setSelectedAttachment({base64: response.base64, title});
            setShowAttachment(true);
        }else{
            AppGlobals.alert(<CustomAlert type={'danger'} title={response.message}/>);
        }
        documentType === 'ssn' ? setSsnIsPreviewing(false) : setDlIsPreviewing(false);
    }

    async function handleSendInvitation(){
        setIsSendingInvitation(true);
        const response = await sendDocumentRequestInvitation(documentRequest.uid);
        if(response.status === 200){
            setShowSendInvitation(false)
        }
        AppGlobals.alert(<CustomAlert type={response.status === 200 ? 'success' : 'danger'} title={response.message}/>);
        setIsSendingInvitation(false);
    }

    const sortedDocuments = ['offerLetter', 'i9', 'w4', ...Object.keys(taxDocumentDictionary), ...Object.keys(DirectDepositDocuments), 'f8850'].filter(d => Object.keys(documentRequest.documents).includes(d));
    const edDocumentElements = sortedDocuments.filter(dType => !documentRequest.documents[dType]?.archived).map((documentType) => {
        return (
            <EdDocumentItem
                key={documentType} 
                documentType={documentType} 
                documentRequest={documentRequest} 
                selectedEmployee={selectedEmployee} 
                handleSetEDDocumentIncomplete={handleSetEDDocumentIncomplete}
                setRevisionMessage={setRevisionMessage} 
                setShowSendBackModal={setShowSendBackModal}
                setDocToPreview={setDocToPreview}
                revisionMessage={revisionMessage}
                setShowPreview={setShowPreview}
                setDocToSendBack={setDocToSendBack}
            />
        )
    });

    const customDocumentElements = documentRequest.documents.custom.map((customDocument) => {
        return (
            <CustomDocumentItem
                key={customDocument.uid} 
                doc={customDocument}
                documentRequest={documentRequest} 
                selectedEmployee={selectedEmployee} 
                handleSetEDDocumentIncomplete={handleSetEDDocumentIncomplete}
                setRevisionMessage={setRevisionMessage} 
                setShowSendBackModal={setShowSendBackModal}
                setDocToPreview={setDocToPreview}
                revisionMessage={revisionMessage}
                setShowPreview={setShowPreview}
                setDocToSendBack={setDocToSendBack}
            />
        )
    })

    const dlAndSsnElements = (
        <>
            <tr>
                <td>
                    Driver's License
                </td>
                <td style={{color: documentRequest.dlDataIdentifier ? 'var(--bs-success)' : 'red'}}>
                    {documentRequest.dlDataIdentifier ? 'Completed' : 'Awaiting Completion'}
                </td>
                <td>
                    <div style={{display: 'flex', gap: 6, justifyContent: 'flex-end'}}>
                        { documentRequest.dlDataIdentifier && 
                            <>
                                <CustomButton title='Preview' style={{padding: '0px 6px 0px 6px'}} label={<FontAwesomeIcon icon={faMagnifyingGlass}/>} isLoading={dlIsPreviewing} onClick={() => {handlePreviewDlSsn('dl')}}/>
                                <CustomButton title='Download' style={{padding: '0px 6px 0px 6px'}} label={<FontAwesomeIcon icon={faDownload}/>} isLoading={dlIsDownloading} onClick={() => handleDownloadSsnOrDl('dl', documentRequest.dlDataIdentifier)} />
                                <Button variant='outline-danger' title='Request Revision' style={{padding: '0px 6px 0px 6px', whiteSpace: 'nowrap'}} onClick={() => {setDocToSendBack('dl'); setShowSendBackModal(true)}}>
                                    <FontAwesomeIcon icon={faThumbsDown}/>
                                </Button>
                            </>
                        }
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Social Security Card
                </td>
                <td style={{color: documentRequest.ssnDataIdentifier ? 'var(--bs-success)' : 'red'}}>
                    {documentRequest.ssnDataIdentifier ? 'Completed' : 'Awaiting Completion'}
                </td>
                <td>
                    <div style={{display: 'flex', gap: 6, justifyContent: 'flex-end'}}>
                        { documentRequest.ssnDataIdentifier && 
                            <>
                                <CustomButton title='Preview' style={{padding: '0px 6px 0px 6px'}} label={<FontAwesomeIcon icon={faMagnifyingGlass}/>} isLoading={ssnIsPreviewing} onClick={() => {handlePreviewDlSsn('ssn')}}/>
                                <CustomButton title='Download' style={{padding: '0px 6px 0px 6px'}} label={<FontAwesomeIcon icon={faDownload}/>} isLoading={ssnIsDownloading} onClick={() => handleDownloadSsnOrDl('ssn', documentRequest.ssnDataIdentifier)} />
                                <Button variant='outline-danger' title='Request Revision' style={{padding: '0px 6px 0px 6px', whiteSpace: 'nowrap'}} onClick={() => {setDocToSendBack('ssn'); setShowSendBackModal(true)}}>
                                    <FontAwesomeIcon icon={faThumbsDown}/>
                                </Button>
                            </>
                        }
                    </div>
                </td>
            </tr>
        </>
    )

    const activityElements = documentRequest.activity.map((item) => {
        return (
            <KeyValueRow isListItem key={item.uid} title={item.isEmployer ? 'Employer' : 'Employee'} value={moment(item.dateTime).format('MMM D, YYYY [at] h:mm A')}/>
        )
    });

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Document Request</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexWrap: 'wrap', marginBottom: 12, gap: 12}}>
                    <Button variant="outline-primary" onClick={() => {setShowSendInvitation(true)}}>Resend Employee Invitation</Button>
                    <a target='_blank' className='btn btn-outline-primary' href={`https://mytacticalrecruiting.com/employee-onboarding/${documentRequest.employerToken}`}>Access Document Request as Employer</a>
                </div>
                { (edDocumentElements.length > 0 || customDocumentElements.length > 0) && 
                    <QuickTable title='Documents' headers={['Title', 'Employee Completed', 'Employer Completed', 'Actions']} rows={[...edDocumentElements, ...customDocumentElements]} widths={[null, null, null, 1]} size='sm'/>
                }
                { ('i9' in documentRequest.documents) && 
                    <QuickTable title={`Driver's License and Social Security Card`} headers={['Title', 'Employee Completed', 'Actions']} rows={dlAndSsnElements} widths={[null, null, 1]} size='sm'/>
                }
                <h5>Request View Activity</h5>
                <ListGroup>
                    { activityElements.length > 0 ?
                        activityElements
                    :
                        <ListGroup.Item>None</ListGroup.Item>
                    }
                </ListGroup>
            </Modal.Body>
            <AlertModal centered show={showSendInvitation} onHide={() => {setShowSendInvitation(false)}} isLoading={isSendingInvitation} title='Resend Employee Invitation?' message='The employee will receive an email with a link to view/complete this document request' buttonLabel='Resend Invitation' callBack={handleSendInvitation}/>
            <Modal show={showSendBackModal} onHide={() => {setShowSendBackModal(false)}} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Send Document Back to Employee to Revise?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Include a message below for the employee with instructions on what needs revised.</span>
                    <Form.Control value={revisionMessage} onChange={(event) => {setRevisionMessage(event.target.value)}} as='textarea' rows={3}/>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton label='Request Revision' isLoading={isSendingBack} onClick={handleSendBackDocument}/>
                </Modal.Footer>
            </Modal>
            <Modal show={showPreview} onHide={() => {setShowPreview(false)}} fullscreen>
                <EDDocumentPreview document={docToPreview}/>
            </Modal>
            <Modal show={showAttachment} onHide={() => {setShowAttachment(false)}} fullscreen>
                <AttachmentPreview document={selectedAttachment}/>
            </Modal>
        </>
    )
}

