/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import LoadingWrapper from "../../components/LoadingWrapper";
import Cookies from "universal-cookie";

export default function Authenticate() {

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');
    new Cookies().set('token', token, {path: '/'});
    setTimeout(() => {
      window.location.replace(window.location.origin);
    }, 1000);
  }, [])




  return (
    <div style={{ height: '100vh', padding: 12, flexDirection: 'column' }}>
      <LoadingWrapper isLoading={true}/>
    </div>
  );
}
