/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Document, Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import { addressToString, availabilityToString, employeeTypeDictionary, formatPhoneNumber, parseOfferLetter, payTypeDictionary, usdFormatter } from "../../../tools";

const defaultOfferLetterData = {
    companyName: "ABC, Inc.",
    employeeName: "Jane Doe",
    employeeType: 0,
    startDate: moment().format("YYYY-MM-DD"),
    supervisorName: "John Doe",
    locationAddress: {
      thoroughfare: "123 Delivery St.",
      premise: "",
      locality: "Richfield",
      postalCode: "44286",
      administrativeArea: "OH",
    },
    payType: 0,
    payRate: 15,
    expectedAvailability: "1111100",
    benefits: "Medical, Dental, Vision",
    offerLetterManagerName: `John Doe`,
    offerLetterManagerPhoneNumber: "1234567890",
    offerLetterManagerEmail: "jdoe@gmail.com",
  };

export default function OfferLetterPdf({offerLetterData = defaultOfferLetterData}) {

    const mergedOfferLetterData = {
        content: offerLetterData.content,
        companyName: offerLetterData?.companyName ?? defaultOfferLetterData.companyName,
        employeeName: offerLetterData?.employeeName ?? defaultOfferLetterData.employeeName,
        employeeType: offerLetterData?.employeeType ?? defaultOfferLetterData.employeeType,
        startDate: offerLetterData?.startDate ?? defaultOfferLetterData.startDate,
        supervisorName: offerLetterData?.supervisorName ?? defaultOfferLetterData.supervisorName,
        locationAddress: offerLetterData?.locationAddress ?? defaultOfferLetterData.locationAddress,
        payType: offerLetterData?.payType ?? defaultOfferLetterData.payType,
        payRate: offerLetterData?.payRate ?? defaultOfferLetterData.payRate,
        expectedAvailability: offerLetterData?.expectedAvailability ?? defaultOfferLetterData.expectedAvailability,
        benefits: offerLetterData?.benefits ?? defaultOfferLetterData.benefits,
        offerLetterManagerName: offerLetterData?.offerLetterManagerName ?? defaultOfferLetterData.offerLetterManagerName,
        offerLetterManagerPhoneNumber: offerLetterData?.offerLetterManagerPhoneNumber ?? defaultOfferLetterData.offerLetterManagerPhoneNumber,
        offerLetterManagerEmail: offerLetterData?.offerLetterManagerEmail ?? defaultOfferLetterData.offerLetterManagerEmail,
      };

    return (
        <Document>
            <Page size="LETTER" style={{ padding: 50, display: 'flex', flexDirection: 'column' }}>
                <View style={{ flexGrow: 1 }}>
                <Text style={{ fontSize: 11.5 }}>{parseOfferLetter(offerLetterData.content, mergedOfferLetterData)}</Text>
                </View>
                <View style={{ height: 80 }} />
                <View style={{ display: 'flex', flexDirection: 'column', marginTop: 'auto' }} wrap={false}>
                <Text style={{ fontSize: 11.5, marginBottom: 60 }}>Please sign and date to confirm receipt of this document.</Text>
                <View style={{ display: 'flex', flexDirection: 'row', gap: 50, fontSize: 11.5 }}>
                    <Text style={{ borderTop: '1px solid black', paddingLeft: 12, paddingRight: 50, paddingTop: 4 }}>Employee's Signature</Text>
                    <Text style={{ borderTop: '1px solid black', paddingLeft: 12, paddingRight: 50, paddingTop: 4 }}>Date</Text>
                </View>
                </View>
            </Page>
        </Document>
    )
}