/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Modal, Spinner } from 'react-bootstrap';
import QuickTable from '../../../components/QuickTable';
import CustomButton from '../../../components/CustomButton';
import { DirectDepositDocuments, getFileIcon, OnboardingDocumentsReadOnly, pandaDocStatusDictionary, saveFile, taxDocumentDictionary } from '../../../tools';
import PageSpinner from '../../../components/PageSpinner';
import { deleteEmployeeDocument, downloadDocumentRequest, getEmployeeDocument, getEmployeeDocuments, sendEmployeeDocument, updateEmployeeDocument } from '../../../services/HumanResourcesService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { faArrowUpRightFromSquare, faDownload, faUpload, faX } from '@fortawesome/free-solid-svg-icons';
import HRDocumentUpload from './HRDocumentUpload';
import ApiControl from '../../../components/ApiControl';
import AlertModal from '../../../components/AlertModals/AlertModal';
import { AppGlobals } from '../../../App';
import CustomAlert from '../../../components/CustomAlert';
import PDFMerger from 'pdf-merger-js/browser';
import DocumentRequestDetails from './DocumentRequestDetails';
import DocumentRequestModal from './DocumentRequestModal';

export default function HRDocuments({selectedEmployee}) {

    const [isLoading, setIsLoading] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [pandaDocs, setPandaDocs] = useState([]);
    const [edDocumentRequests, setEdDocumentRequests] = useState([]);

    const [selectedDocument, setSelectedDocument] = useState(undefined);
    const [selectedEdDocumentRequest, setSelectedEdDocumentRequest] = useState(null);
    const [showDocumentRequestDetails, setShowDocumentRequestDetails] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showDocumentRequestModal, setShowDocumentRequestModal] = useState(false);

    const [modalSwitch, setModalSwitch] = useState(false);
    const [documentToDelete, setDocumentToDelete] = useState(undefined);
    const [documentToSend, setDocumentToSend] = useState(undefined);

    
    const loadData = async () => {
        setIsLoading(true);
        const response = await getEmployeeDocuments(selectedEmployee.uid);
        if (response.status === 200) {
            setDocuments(response.documents);
            setPandaDocs(response.pandaDocs);
            setEdDocumentRequests(response.edDocumentRequests);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, [selectedEmployee])

    function handleSetEDDocumentIncomplete(documentRequestIdentifier, documentType) {
        const newArray = Array.from(edDocumentRequests);
        const request = newArray.find(r => r.uid == documentRequestIdentifier);
        if(typeof documentType === 'number'){
            const document = request.documents.custom.find((customDoc) => customDoc.uid === documentType)
            document.employeeCompleted = null;
        }else{
            request.documents[documentType].employeeCompleted = null;
            if('employerCompleted' in  request.documents[documentType]){
                request.documents[documentType].employerCompleted = null;
            }
        }
        setEdDocumentRequests(newArray);
    }

    const handleDeleteDocument = async () => {
        const response = await deleteEmployeeDocument(documentToDelete);
        if (response.status === 200) {
            handleDocumentCrud('delete', documentToDelete);
            return true;
        }
        return false;
    }

    const handleDownloadDocument = async (uid) => {
        const response = await getEmployeeDocument(uid);
        if (response.status === 200) {
            saveFile(response.document.name, response.document.base64);
        } else {
            AppGlobals.alert(<CustomAlert type={'danger'} title={response.message ?? 'Failed to Download Document'}/>)
        }
    }

    const handleDocumentCrud = (type, data) => {
        let newArr = Array.from(documents);
        if (type === 'create') {
            newArr.push(data);
        } else if (type === 'update') {
            newArr = newArr.filter(t => t.uid != data.uid);
            newArr.push(data);
        } else if (type === 'delete') {
            newArr = newArr.filter(t => t.uid != data);
        }
        setDocuments(newArr);
        setModalSwitch('');
    }

    const handleSetDocumentName = (uid, value) => {
        let newArr = Array.from(documents);
        newArr.find(d => d.uid == uid).name = value;
        setDocuments(newArr);
    }

    const documentRows = documents.map((document) => {
        return (
            <tr key={document.uid} style={{verticalAlign: 'middle'}}>
                <td>
                    <ApiControl value={document.name} maxLength={100} api={async (value) => {return await updateEmployeeDocument({...document, name: value})}} setValue={(value) => {handleSetDocumentName(document.uid, value)}}/>
                </td>
                <td>
                    <FontAwesomeIcon icon={getFileIcon(document.type)} style={{color: 'var(--bs-primary)', marginRight: 4}}/>
                    <span>{document.type}</span>
                </td>
                <td>{moment(document.dateCreated).format('MMM D, YYYY')}</td>
                <td>
                    <div style={{display: 'flex', gap: 12}}>
                        <Button variant='outline-primary' onClick={() => {handleDownloadDocument(document.uid)}}>
                            <FontAwesomeIcon icon={faDownload}/>
                        </Button>
                        <Button variant='outline-danger' onClick={() => {setDocumentToDelete(document.uid)}}>
                            <FontAwesomeIcon icon={faX}/>
                        </Button>
                    </div>
                </td>
            </tr>
        )
    });

    const pandaDocRows = pandaDocs.map((pd) => {
        return (
            <tr key={pd.uid}>
                <td>{pd.name}</td>
                <td>{moment(pd.dateCreated).format('MMM D, YYYY')}</td>
                <td>{pandaDocStatusDictionary[pd.status]}</td>
                <td>
                    <a target='_blank' href={pd.link}>
                        <FontAwesomeIcon style={{color: 'var(--bs-primary)'}} icon={faArrowUpRightFromSquare}/>
                    </a>
                </td>
            </tr>
        )
    });

    const edRequestElements = edDocumentRequests.map((request) => {
        return (
            <DocumentRequestElement key={request.uid} request={request} selectedEmployee={selectedEmployee} setSelectedEdDocumentRequest={setSelectedEdDocumentRequest} setShowDocumentRequestDetails={setShowDocumentRequestDetails}/>
        )
    })

    return isLoading ? <PageSpinner/> : (
        <div>
            <div style={{display:'flex', justifyContent:'flex-end', marginBottom: 8, gap: 8, alignItems: 'center'}}>
                { (!selectedEmployee.isTerminated || selectedEmployee.firstUpcomingPayDateEffective) &&
                    <div className="d-flex gap-3">
                        <Button variant={'outline-primary'} style={{ whiteSpace: 'nowrap'}} onClick={() => setModalSwitch('upload')}><FontAwesomeIcon size='xl' icon={faUpload}/></Button>
                        <Button variant='outline-primary' style={{ whiteSpace: 'nowrap'}} onClick={() => setShowDocumentRequestModal(true)}>Send Employee Document Request</Button>
                    </div>
                }
            </div>
            {edRequestElements.length > 0 && <QuickTable noWrap title='Document Requests' headers={['Date Created', 'Documents Requested', 'Status', '']} rows={edRequestElements} size='sm' hover/>}
            {pandaDocRows.length > 0 && <QuickTable title='Onboarding Document(Panda Docs)' headers={['Name', 'Date Created', 'Status', 'Link']} widths={[null, null, null, 1]} rows={pandaDocRows} size='sm'/>}
            {documentRows.length > 0 && <QuickTable title='Documents' headers={['Name', 'Type', 'Date Created', 'Actions']} widths={[null, null, null, 1]} rows={documentRows}  size={'sm'}/>}
            <Modal show={modalSwitch === 'upload'} onHide={()=> {setModalSwitch('')}} size='lg'>
                <HRDocumentUpload userIdentifier={selectedEmployee.uid} handleDocumentCrud={handleDocumentCrud}/>
            </Modal>
            <Modal size='xl' show={showDocumentRequestDetails} onHide={() => setShowDocumentRequestDetails(false)}>
                <DocumentRequestDetails selectedEmployee={selectedEmployee} documentRequest={selectedEdDocumentRequest} handleSetEDDocumentIncomplete={handleSetEDDocumentIncomplete}/>
            </Modal>
            <Modal size='xl' show={showDocumentRequestModal} onHide={() => setShowDocumentRequestModal(false)}>
                <DocumentRequestModal userIdentifier={selectedEmployee.uid} hideModal={() => {setShowDocumentRequestModal(false); loadData()}}/>
            </Modal>
            <AlertModal 
                show={documentToDelete !== undefined} 
                onHide={() => {setDocumentToDelete(undefined)}} 
                title='Delete Document?' 
                message={'This cannot be undone.'} 
                buttonLabel={'Delete Document'} 
                callBack={handleDeleteDocument}
            />
        </div>
    )
}

function DocumentRequestElement({request, selectedEmployee, setSelectedEdDocumentRequest, setShowDocumentRequestDetails}) {
    const [isDownloadingRequest, setIsDownloadingRequest] = useState(undefined);

    const handleDownloadDocumentRequest = async () => {
        setIsDownloadingRequest(request.uid);
        const response = await downloadDocumentRequest(request.uid);
        console.log('here this is the response', response)
        if (response.status == 200) {
            const merger = new PDFMerger();
    
            const sortedDocumentTypes = ['offerLetter', 'i9', 'w4', ...Object.keys(taxDocumentDictionary), ...Object.keys(DirectDepositDocuments), 'f8850'].filter(d => Object.keys(response.documents).includes(d));
    
            for (let i = 0; i < sortedDocumentTypes.length; i++) {
                const document = response.documents[sortedDocumentTypes[i]];
                if (document.base64) {
                    const fetchResponse = await fetch(document.base64);
                    const blob = await fetchResponse.blob();
                    await merger.add(blob, null);
                }
            }
    
            for (const customDocument of response.documents.custom) {
                if (customDocument.base64) {
                    const fetchResponse = await fetch(customDocument.base64);
                    const blob = await fetchResponse.blob();
                    await merger.add(blob, null);
                }
            }
    
            const mergedPdf = await merger.saveAsBlob();
    
            const elem = window.document.createElement('a');
            elem.target = '_blank';
            elem.href = window.URL.createObjectURL(mergedPdf);
            elem.download = `${selectedEmployee?.firstName ?? ''} ${selectedEmployee?.lastName ?? ''} Document Request ${moment(request.dateCreated).format('MMM D, YYYY')}`;        
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
        setIsDownloadingRequest(undefined);
    }

    // async function handleDownloadDocumentRequest(documentRequest){
    //     setIsDownloadingRequest(true);
    //     const response = await downloadDocumentRequest(documentRequest.uid);
    //     if(response.status === 200){
    //         const merger = new PDFMerger();
    //         const sortedDocumentTypes = ['offerLetter', 'i9', 'w4', ...Object.keys(taxDocumentDictionary), 'dd', 'mdd', 'f8850'].filter(d => Object.keys(response.documents).includes(d));

    //         for (let i = 0; i < sortedDocumentTypes.length; i++) {
    //             const document = response.documents[sortedDocumentTypes[i]];
    //             if (document.base64) {
    //                 const fetchResponse = await fetch(document.base64);
    //                 const blob = await fetchResponse.blob();
    //                 await merger.add(blob, null);
    //             }
    //         }

    //         for (const customDocument of response.documents.custom) {
    //             if (customDocument.base64) {
    //                 const fetchResponse = await fetch(customDocument.base64);
    //                 const blob = await fetchResponse.blob();
    //                 await merger.add(blob, null);
    //             }
    //         }
    
    //         const mergedPdf = await merger.saveAsBlob();
    //         const elem = window.document.createElement('a');
    //         elem.target = '_blank';
    //         elem.href = window.URL.createObjectURL(mergedPdf);
    //         elem.download = `${selectedEmployee?.firstName ?? ''} ${selectedEmployee?.lastName ?? ''} Document Request ${moment(documentRequest.dateCreated).format('MMM D, YYYY')}`;        
    //         document.body.appendChild(elem);
    //         elem.click();
    //         document.body.removeChild(elem);
    //     }else{
    //         AppGlobals.alert(<CustomAlert type={'danger'} title={response.message ?? 'Failed to Download Document Request'}/>)
    //     }
    //     setIsDownloadingRequest(false);
    // }

    const includedDocuments =  Object.keys(request.documents);
    let documents = ['offerLetter', 'i9', 'w4', ...Object.keys(taxDocumentDictionary), 'dd', 'f8850'].filter(d => includedDocuments.includes(d)).map(d => OnboardingDocumentsReadOnly[d]);

    if(request.documents.i9){
        documents.push(`Driver's License`)
        documents.push('Social Security Card')
    }
    if(request.documents.custom.length > 0){
        documents.push(...request.documents.custom.map((document) => document.documentInfo.name))
    }
    documents = documents.join(', ');

    const status = Object.keys(request.documents).reduce((prev, curr) => {
        const documentValue = request.documents[curr];
    
        if (Array.isArray(documentValue)) {
            for (const doc of documentValue) {
                if (!doc.employeeCompleted && !doc.archived) {
                    return 'Awaiting Employee';
                }
            }
        } else {
            if (!documentValue.employeeCompleted && !documentValue.archived) {
                return 'Awaiting Employee';
            } else if ('employerCompleted' in documentValue && !documentValue.employerCompleted && !documentValue.archived) {
                return 'Awaiting Employer';
            }
        }
    
        if ((request.i9 && !request.dlDataIdentifier) || (request.i9 && !request.ssnDataIdentifier)) {
            return 'Awaiting Employee';
        }
    
        return prev;
    }, 'Complete');
    
    
    return (
        <tr className='cursor-pointer' key={request.uid} onClick={() => {setSelectedEdDocumentRequest(request); setShowDocumentRequestDetails(true)}}>
            <td>{moment(request.dateCreated).format('MMM D, YYYY')}</td>
            <td>{documents}</td>
            <td>{status}</td>
            <td>
                <CustomButton 
                    title='Download' 
                    style={{padding: '0px 6px 0px 6px'}} 
                    label={<FontAwesomeIcon icon={faDownload}/>} 
                    isLoading={isDownloadingRequest} 
                    onClick={(event) => {handleDownloadDocumentRequest(); event.stopPropagation()}}
                />
            </td>
        </tr>
    )
}